import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";

import './index.css';
import "antd/dist/reset.css";
import "survey-core/survey.i18n.js";
import 'survey-core/defaultV2.min.css';
import "survey-creator-core/survey-creator-core.i18n.js";
import "survey-creator-core/survey-creator-core.css";
import reportWebVitals from './reportWebVitals';
import { Application } from './Application';
import { setLicenseKey } from 'survey-core';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://30f723eafff29bda3e663aeea4b66899@o4505876674314240.ingest.sentry.io/4505876679557120",
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// domains:edeal.ai;0=2029-01-01,1=2029-01-01,2=2029-01-01
//setLicenseKey('ZG9tYWluczplZGVhbC5haTswPTIwMjktMDEtMDEsMT0yMDI5LTAxLTAxLDI9MjAyOS0wMS0wMQ==');

// Create app with react-router, i18n, redux and apollo
root.render(<Application />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
